import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Footer from '../../components/layout/footer';
import SEO from '../../components/seo/seo';

import usePaypal from '../../components/paypal/use-paypal';

import './shop.css';

const ShopPage = ({
  handleQuantityChange,
  quantity,
  status,
}) => (
  <>
    <SEO title="Shop" />
    <main className="shop">
      <div className="shop__inner">
        <h1>Shop</h1>
        <p>
          Get your print copy of
          {' '}
          <cite>
            The Sockpicker
          </cite>
          . The first 50 copies sold are signed by the author and illustrator! The print edition is
          an 8x10&quot; paperback, 30 pages, with premium matte paper.
        </p>
        <div className="shop__cover">
          <StaticImage
            alt="The Sockpicker book cover"
            aspectRatio={818 / 1226}
            height={300}
            layout="constrained"
            loading="eager"
            objectFit="contain"
            placeholder="tracedSVG"
            src="../../images/cover.png"
          />
        </div>
        <div id="smart-button-container">
          <div className="shop__paypal-container">
            <p className="shop__description">
              All shipping fees are included in the price. 13% HST is added to all orders.
            </p>
            {
              status.success
              && (
                <p className="shop__status shop__status_success">
                  {status.message}
                </p>
              )
            }
            {
              status.error
              && (
                <p className="shop__status shop__status_error">
                  Whoops... something went wrong with your transaction. Please try again.
                </p>
              )
            }
            <div className="shop__paypal-selection">
              <select id="item-options">
                <option value="Canadian Mailing Address " price="30.00">Canadian Mailing Address - 30.00 CAD</option>
                <option value="US Mailing Address " price="33.00">US Mailing Address - 33.00 CAD</option>
                <option value="Overseas Mailing Address" price="38.00">Overseas Mailing Address - 38.00 CAD</option>
              </select>
              <div>
                <label htmlFor="quantitySelect">
                  Quantity:
                  {' '}
                  <input
                    aria-label="quantity"
                    id="quantitySelect"
                    onChange={handleQuantityChange}
                    min={1}
                    step={1}
                    type="number"
                    value={quantity}
                  />
                </label>
              </div>
            </div>
            <div id="paypal-button-container" />
          </div>
        </div>
      </div>
      <Footer links={['author', 'contact', 'home']} />
    </main>
  </>
);

ShopPage.propTypes = {
  handleQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  status: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
    success: PropTypes.bool,
  }).isRequired,
};

const ShopPageContainer = () => {
  const [quantity, setQuantity] = useState(1);

  const status = usePaypal();

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  return (
    <ShopPage
      handleQuantityChange={handleQuantityChange}
      quantity={quantity}
      status={status}
    />
  );
};

export default ShopPageContainer;
