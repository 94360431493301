import PropTypes from 'prop-types';
import React from 'react';

import Link from '../link/link';

import './footer.css';

const addLink = (link, route, links, text) => (
  links.includes(link)
    && (
      <Link
        linkType="plain"
        to={route}
      >
        {text}
      </Link>
    )
);

const Footer = ({
  links,
}) => (
  <footer className="footer">
    <div className="footer__links">
      {addLink('home', '/', links, 'Home')}
      {addLink('shop', '/shop', links, 'Buy the book')}
      {addLink('author', '/author', links, 'About the author')}
      {addLink('contact', '/contact', links, 'Contact')}
    </div>
    <div className="footer__copyright">
      © Margaret Randall and Sarah Knight,
      {' '}
      {new Date().getFullYear()}
      .
    </div>
  </footer>
);

Footer.defaultProps = {
  links: ['author', 'contact', 'shop'],
};

Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.string),
};

export default Footer;
