import { useState } from 'react';
import useOnMount from '../../utils/use-on-mount';

const usePaypal = () => {
  const [status, setStatus] = useState({});

  const loadScript = () => {
    function initPayPalButton() {
      const shipping = 0;
      const itemOptions = document.querySelector('#smart-button-container #item-options');
      const quantitySelect = parseInt(document.querySelector('#smart-button-container #quantitySelect'), 10);
      let orderDescription = 'Get your print copy of The Sockpicker by Margaret Randall. '
        + 'The first 50 copies sold are signed by the author and illustrator!';
      if (orderDescription === '') {
        orderDescription = 'Item';
      }
      // eslint-disable-next-line no-undef
      paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'paypal',

        },
        createOrder(data, actions) {
          const selectedItemDescription = itemOptions.options[itemOptions.selectedIndex].value;
          const selectedItemPrice = parseFloat(itemOptions.options[itemOptions.selectedIndex].getAttribute('price'));
          let tax = selectedItemPrice * (parseFloat(13) / 100);
          const quantity = quantitySelect.value > 0 ? quantitySelect.value : 1;

          tax *= quantity;
          tax = Math.round(tax * 100) / 100;
          let priceTotal = quantity * selectedItemPrice + parseFloat(shipping) + tax;
          priceTotal = Math.round(priceTotal * 100) / 100;
          const itemTotalValue = Math.round((selectedItemPrice * quantity) * 100) / 100;

          return actions.order.create({
            purchase_units: [{
              description: orderDescription,
              amount: {
                currency_code: 'CAD',
                value: priceTotal,
                breakdown: {
                  item_total: {
                    currency_code: 'CAD',
                    value: itemTotalValue,
                  },
                  shipping: {
                    currency_code: 'CAD',
                    value: shipping,
                  },
                  tax_total: {
                    currency_code: 'CAD',
                    value: tax,
                  },
                },
              },
              items: [{
                name: selectedItemDescription,
                unit_amount: {
                  currency_code: 'CAD',
                  value: selectedItemPrice,
                },
                quantity,
              }],
            }],
          });
        },
        onApprove(data, actions) {
          return actions.order.capture().then((details) => {
            setStatus({
              error: false,
              message: `Your order is complete ${details.payer.name.given_name}! It will ship shortly.`,
              success: true,
            });
          });
        },
        onError() {
          setStatus({
            error: true,
            success: false,
          });
        },
      }).render('#paypal-button-container');
    }
    initPayPalButton();
  };

  useOnMount(() => {
    const script = document.createElement('script');
    script.onload = loadScript;
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_ID}&currency=CAD`;
    script.setAttribute('data-sdk-integration-source', 'button-factory');
    document.head.appendChild(script);
  });

  return status;
};

export default usePaypal;
